import React, { Component } from 'react';
import "./navbar.css";
import axios from 'axios';
import { withRouter, Link, NavLink} from "react-router-dom";
import { BsCardChecklist, BsGear, BsMegaphone, BsPerson, BsBarChart, BsBook, BsHouse } from 'react-icons/bs';
import NavbarItem from "../navbar_item/navbar_item.component"


class Navbar extends Component {
    // lol these should probably be separate components
    constructor(props) {
        super(props)
    }

    componentDidMount = () => {
        console.log('navbar remounted')
    }

    handleClick = (item_class) => {
        // let new_state = {
        //     checklist_class: '',
        //     goals_class: '',
        //     feedback_class: '',
        //     profile_class: '',
        // }
        // new_state[item_class] = 'active'
        // this.setState(new_state, () => console.log(this.state))
    }

    render() {
        return (
            <div className="navbar">
                <NavLink to="/checklist">
                    <li className={this.props.checklist_class} onClick={() => this.props.set_active_navbar('checklist_class')}>

                            <BsHouse size={20}></BsHouse>
                            home 
                    </li>
                </NavLink>

                <NavLink to="/goals"><li className={this.props.goals_class} onClick={() => this.props.set_active_navbar('goals_class')}><BsBarChart size={20}></BsBarChart>goals</li></NavLink>
                <NavLink to="/feedback"><li className={this.props.feedback_class} onClick={() => this.props.set_active_navbar('feedback_class')}><BsMegaphone size={20}></BsMegaphone> feedback</li></NavLink>
                <NavLink to="/profile"><li className={this.props.profile_class} onClick={() => this.props.set_active_navbar('profile_class')}><BsPerson size={20}/>profile</li></NavLink>
            </div>
        );
    }
}

export default withRouter(Navbar)
