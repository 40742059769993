import React, { Component } from 'react';
import "./login.css";
import axios from 'axios';
import {withRouter, Link} from 'react-router-dom';
import history from '../../history.js'
import ColorbarHeader from "../colorbar_header/colorbar_header.component";


class Login extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            email: "",
            password: "", 
            clickable: '',
            errorMessage: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    componentDidUpdate = (prevProps, prevState) => {
        const state_items = Object.values(this.state).slice(0, 2)
        const prev_state_items = Object.values(prevState).slice(0, 2)
        console.log(state_items, prev_state_items)
        if (!(this.arraysEqual(state_items, prev_state_items))) {
            if (state_items.every(val => val !== '')) {
                this.setState({
                    clickable: 'clickable'
                }, () => console.log(this.state))
            }
            else {
                this.setState({
                    clickable: ''
                })
            }
        }
    }
    
    handleSubmit(event)
    {
        event.preventDefault();
        const userLogin = {
            email: this.state.email, 
            password: this.state.password
        }
        this.props.log_user_in(userLogin)
    }

    handleClick = () => {
        history.push('/signup')
    }

    render()
    {
        return (
            <div className = "full-container">
                <ColorbarHeader header_text="Welcome back!"/>
                <div className='container'>
                    <form id="signup-form" onSubmit={this.handleSubmit}>
                        <div className='signup-label'>
                            <label>Email</label>
                        </div>
                        <div className='input-field'>
                            <input className="form-control signup-input" required value={this.state.email} onChange={(event) => this.setState({email: event.target.value.toLowerCase()})}></input>
                        </div>
                        <div className='signup-label'>
                            <label>Password</label>
                        </div>
                        <div className='input-field'>
                            <input className="form-control signup-input" required type="password" value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}></input>
                        </div>
                        <p className = "errorMessage">{this.state.errorMessage}</p>
                    
                        <div className='centered-button'>
                            <button type="submit" className={`continue signup ${this.state.clickable}`} id="signup-button">Login</button>
                            <div className='signup-alternative'><Link to="/signup" style={{color: '#4c8bf5'}}>Create an Account</Link></div>
                        </div>
                    </form>
            </div>

            </div>
        );
    }
}

export default withRouter(Login)
