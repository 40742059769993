import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter, Redirect } from "react-router-dom";
import axios from "axios"
import "./connected_accounts_container.css"
import ConnectedAccountBox  from "../connected_account_box/connected_account_box.component"
// import { update } from '../../models/users.model';
// import body text dict


class ConnectedAccountsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            account_type: this.props.account_type
        };
        this.remove_account = this.remove_account.bind(this)

    } 


    componentDidMount(){
        console.log()
        this.get_accounts()
        // get accounts for specific type 
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.state)
    }

    get_accounts = async() => {
        console.log('running get accounts')
        const res = await axios.get(`http://localhost:5000/plaid/connected_accounts/${this.state.account_type}`, 
            {withCredentials: true}  // send the requested products in the body as an array
        );
        console.log(res.data)
        this.setState({accounts: res.data}, () =>console.log(this.state) )

    }

    remove_account = (account_index) => {
        this.setState(prevState => {
            let new_state = prevState['accounts'];
            console.log(account_index)
            new_state.splice(account_index, 1)
            return {'accounts' : new_state};                                
        }, () => {
            console.log('after update', this.state)
            const res =  axios.put(`http://localhost:5000/plaid/update_accounts/${this.state.account_type}`,
                        {'accounts': this.state.accounts}, 
                        {withCredentials: true}  // send the requested products in the body as an array
                    );
        })   
    }


    render() {
        return (
            <div className='connected_accounts_container'> 
                <p style={{textAlign: 'center', fontSize: '1.2em', 'fontWeight': '500'}}>Connected Accounts</p>
                {this.state.accounts.map((account, index) => 
                    <ConnectedAccountBox 
                        account_name={account.institution_name}
                        remove_account={this.remove_account}
                        account_index={index}
                        key={index}
                        last={index === this.state.accounts.length-1? 'last' : ''}
                        first={index === 0 ? 'first' : ''}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(ConnectedAccountsContainer)