import React, { Component } from 'react';
import "./navbar_item.css";
import axios from 'axios';
import { withRouter, Link} from "react-router-dom";
import { BsCardChecklist, BsGear, BsMegaphone, BsPerson, BsBarChart, BsBook, BsHouse } from 'react-icons/bs';


class NavbarItem extends Component {
    // lol these should probably be separate components
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            
            class: ''
        }
    }

    handleClick = () => {
        this.setState(prevState => ({
            active: !prevState.active
        }), () => {
            if (this.state.active === true) {
                this.setState({
                    class: 'active'
                })
            }
            else {
                this.setState({
                    class: ''
                })
            }
        }
        );
    }

    render() {
        return (
                <Link to={this.props.destination}>
                    <li className={`${this.state.class} navbar-item`} onClick={this.handleClick}>
                        {this.props.icon}
                        {this.props.text}
                    </li>
                </Link>
        );
    }
}

export default withRouter(NavbarItem)