import React, { Component } from 'react';
import "./bubble_card.css";
import axios from 'axios';
import { withRouter, Link} from "react-router-dom";
import { BsPiggyBank, BsCurrencyDollar, BsUnlock, BsChevronRight, BsBarChart} from 'react-icons/bs';
import Navbar from "../navbar/navbar.component.js"
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import history from '../../history.js'



class BubbleCard extends Component {
    constructor(props) {
        super(props)
    }

    handleClick = () => {
        if (this.props.go_forward) {
            const next_page = this.props.next_page;
            history.push(next_page);
        }
    }

    render() {
        return (
            <div className={`settings-card ${this.props.size}`} onClick={this.handleClick}>
                    <div className='icon'>
                        {this.props.logo}
                    </div>
                    <div className='title'>
                        {this.props.text}
                        {this.props.subtext? 
                            <p className='subheading'>{this.props.subtext}</p> : 
                            null 
                        }
                    </div>
                    {
                        this.props.go_forward? 
                        <div className='settings-right'>
                            <BsChevronRight size={20}></BsChevronRight>
                        </div> : 
                        null
                    }


            </div>
        );
    }
}

export default withRouter(BubbleCard)

