import React, { Component } from 'react';
import "./big_text_transition.css"
import Button from "../continue_button/continue_button.component";
import money_hands from '../../assets/money_hands.png'
import { Link, withRouter } from "react-router-dom";

 

// todo figure out how to best practice to change the color and words of this header
// i think that we just use props and pass in text and a color maybe, then re-render the bar? 
// each page will have its own colorbar component
class BigTextTransition extends Component {
    render() {
        return (
            <div className="full-container big-text">
                <div className='container' style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
                    <img className='transition' src={money_hands}></img>
                    <h1 className='transition'>{this.props.transition_text}</h1>
                    <Link to={this.props.next_page}>
                        <div className='centered-button fade-in-delay'>
                            <button type="submit" className="continue clickable">Continue</button>
                        </div>
                    </Link>
                </div>
                {/* <Button next_page={this.props.next_page}/> */}
            </div>
        )
    }
}

export default withRouter(BigTextTransition)