import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter, Redirect } from "react-router-dom";
// import Button from "../button/button.component";
import CustomPlaidLink from '../plaid_link_button/Link.component';
import ProgressBar from '../progress_bar/progress_bar.component';
import NavigateCircle from '../navigate_button/navigate_button.component';
import ConnectedAccountsContainer from '../connected_accounts_container/connected_accounts_container.component'
import ManualEntryHandler from '../manual_entry_handler/manual_entry_handler.component'
import AddDebtPopup from '../add_debt_popup/add_debt_popup.component'
import axios from "axios"
import { BottomModal } from "../modal/modal.component";


import "./connect_accounts.css"
// import body text dict

let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}

class ConnectAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            manual_option: false,
            access_token: null,
            next_page: null,  // move 2 props
            end_destination: '/accounts_connected',
            accounts_position: this.props.accounts_position, 
            done_connecting: false,
            amount: null,
            adding_loan: false,
            student_loan: [
                // {institution_name: 'Chase bank sdfds', 'interest_rate': 5, 'loan_amount': 1000},
                // {institution_name: 'Chase', 'interest_rate': 5, 'loan_amount': 1000}

            ],
            credit_card_debt: [],
            body_text: {
                'savings': 'Connect your account so we can help you build your emergency fund!', 
                'ira': 'u should get an ira', 
                'checking': "You should have at least 3-6x your monthly expenses saved up in case of emergencies. We'll use your checking and savings balance to determine how much you need!", 
                'student_loan': 'Connect your student loan accounts so that we can figure out the optimal schedule for you to pay them off.', 
                'credit_card_debt': 'Connect your credit cards so that we can figure out the optimal schedule for you to pay off your debt', 
                'car_loan': 'you needed a car'
            },

            plaid_product_mapping: {
                'savings': ['transactions'], 
                'ira': ['transactions', 'investment'], 
                'checking': ['transactions'], 
                'student_loan': ['liabilities'], 
                'credit_card_debt': ['liabilities'], 
                'car_loan': ['liabilities']
            }, 

            header_map: {
                'ira': 'u should get an ira', 
                'checking': "Emergency Fund", 
                'student_loan': 'Student Loans', 
                'credit_card_debt': 'Credit Card Debt', 
            }
        };
        this.getAccessToken = this.getAccessToken.bind(this)
        this.setClickable = this.setClickable.bind(this)
        this.extendState = this.extendState.bind(this)
        this.toggle_adding_loan = this.toggle_adding_loan.bind(this)
        this.add_debt = this.add_debt.bind(this)
        this.remove_account = this.remove_account.bind(this)

    } 



    componentDidMount(){        
        this.getAccounts()
        const account_type = this.props.match.params.account_type
        const products = this.state.plaid_product_mapping[account_type]
        this.props.create_link_token(products)

        if (this.props.get_next_page !== undefined) {
            const end_destination = this.state.end_destination
            const url = this.props.url;
            const page_collection = this.props.page_collection
            const accounts_position = this.state.accounts_position
            const next_page = this.props.get_next_page(url, page_collection, accounts_position, end_destination);
            this.setState({next_page: next_page}, () => {
                // console.log('after mounting', this.state)
            })
        }
        else {
            this.setState({next_page: this.props.next_page})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('component updated, heres the prev state: ', prevState)
        // console.log('current state', this.state)
        // TODO add something here to handle back button and update the roundup position
        // if this.props.match.params.roundup_item !== the account type or something then -= 1
        if (this.props.accounts_position !== prevState.accounts_position) {
            this.setState({'accounts_position': this.props.accounts_position})
            this.setState({'account_type': this.props.match.params.account_type})
            const url = this.props.url;
            const page_collection = this.props.page_collection
            const accounts_position = this.props.accounts_position // could be state but its async
            const next_page = this.props.get_next_page(url, page_collection, accounts_position, this.state.end_destination);
            this.setState({next_page: next_page}, () => {
                // console.log('new next page', next_page)
                // console.log('new state after updating next page', this.state)
            })
        }        

        // if (this.props.match.params.account_type === 'student_loan') {
        //     if (this.state.student_loan !== prevState.student_loan) {
        //         if (this.state.student_loan.length > 0) {
        //             this.setClickable('clickable')
        //         }
        //         else {
        //             // TODO this doesn't actually work
        //             this.setClickable('')
        //         }
        //     }
        // } 
        
        // if (this.props.match.params.roundup_item !== this.state.account_type) {
        //     this.props.decrease_roundup_position()
        //     console.log(this.props.match.params.roundup_item, this.state.account_type)
        // }
    }

    getAccessToken = async (publicToken, account_types, next_page) => {
        // console.log("client side public token", publicToken)
        this.setState({done_connecting: false})
        //sends the public token to the app server
        const res = await axios.post(URL + '/plaid/get_access_token', 
            {publicToken: publicToken, account_types: account_types}, 
            {withCredentials: true}
            )
        const access_token = res.data.access_token
        this.setState({ access_token: access_token}, this.updateProgress())

    }

    getAccounts = async() => {
        const accessToken = this.props.access_token;
        // const res = await axios.get(`http://localhost:5000/plaid/emergency-fund-balance/${accessToken}`)
        this.setState({isLoading: false})
    }

    updateProgress = () => {
        //run this after we successfully connect something!
        this.props.update_progress_bar()
        this.setState({'done_connecting': true})
    }

    increase_page_position = () => {
        if (this.state.next_page !== this.state.end_destination) {
            this.props.increase_page_position('accounts_position')
        }
    }

    go_forward = () => {
        this.increase_page_position()
        this.setState({'done_connecting': false})
    }

    handleClick = () => {
        this.setState(prevState => ({
            manual_option:!prevState.manual_option
        }))
    }

    handleManualEntryClick = async() => {
        this.increase_page_position()
        this.updateProgress()
        this.setState({done_connecting: false})
        // depending on the account type we need to run the endpoint
        if (this.props.match.params.account_type === 'checking') {
            const res = await axios.put(URL + '/main/emergency_fund_amount', 
                {
                    emergency_fund_amount: this.state.emergency_fund_amount,
                    monthly_expenses: this.state.monthly_expenses
                }, 
                {withCredentials: true}
            ) 
            console.log(res.data)

        }
        else if (this.props.match.params.account_type ==='student_loan') {
            const res = await axios.put(URL + '/main/student_loans', 
                {student_loan: this.state.student_loan}, 
                {withCredentials: true}
            ) 
            console.log(res.data)
        }
        else if (this.props.match.params.account_type === 'credit_card_debt') {
            console.log('running credit card debt')

            const res = await axios.put(URL + '/main/credit_card_debt', 
                {credit_card_debt: this.state.credit_card_debt}, 
                {withCredentials: true}
            ) 
        }
    }

    setClickable = (clickable) => {
        this.setState({
            clickable: clickable
        })
    }

    extendState = (child_state) => {
        this.setState(child_state)
    }

    add_debt = (new_debt, type) => {
        console.log(new_debt, type)
        
        this.setState(prevState => ({
            [type]: [...prevState[type], new_debt]
        }), () => console.log(this.state))

    }
    toggle_adding_loan = (adding_loan_status) => {
        this.setState(prevState => ({
            adding_loan: adding_loan_status
        }), () => {console.log(this.state.adding_loan)})

    }

    remove_account = (account_index, type) => {
        this.setState(prevState => {
            let new_state = prevState[type];
            console.log(account_index)
            new_state.splice(account_index, 1)
            return {'accounts' : new_state};                                
        }, () => {
            console.log('after update', this.state)
            const res =  axios.put(`http://localhost:5000/plaid/update_accounts/${this.state.account_type}`,
                        {'accounts': this.state.accounts}, 
                        {withCredentials: true}  // send the requested products in the body as an array
                    );
        })   
    }

    render() {
        if (this.state.redirect) {
            {console.log('redireting to', this.state.redirect)}
            return <Redirect to={this.state.redirect}/>
        }
        return (
            <div className='full-container'>
                <ProgressBar progress={this.props.connect_accounts_progress}></ProgressBar>
                <div className='header'><p>{this.state.header_map[this.props.match.params.account_type]}</p></div>
                <div className='line'></div>
                {/* body_text style in app.css */}
                {this.state.done_connecting & this.state.manual_option === false ? 
                    <ConnectedAccountsContainer account_type={this.props.match.params.account_type}/>
                    :
                    <>
                        {this.state.manual_option === true & ['student_loan', 'credit_card_debt'].includes(this.props.match.params.account_type)? 
                            null :
                            <div className='body_text'><p className='normal-text'>{this.state.body_text[this.props.match.params.account_type]}</p></div>
                        }
                    </>
                }
                {this.state.manual_option?
                <ManualEntryHandler
                    account_type={this.props.match.params.account_type}
                    set_clickable={this.setClickable}
                    extendState={this.extendState}  
                    toggle_adding_loan={this.toggle_adding_loan}
                    student_loan={this.state.student_loan}
                    credit_card_debt={this.state.credit_card_debt}
                    remove_account={this.remove_account}
                />:
                null
                }
                {/* TODO update below to be just some other classname div */}
                <div className='continue_button'>
                    <div className='nav_circle left_side'></div>
                    <div className='center-connect'>
                        {this.state.manual_option ?
                            <Link to={this.state.next_page}>
                                <button 
                                    className={`continue ${this.state.clickable}`}
                                    onClick={this.handleManualEntryClick}
                                    style={{width: '100%'}}
                                >
                                    Continue
                                </button>
                            </Link> :
 
                            <CustomPlaidLink 
                                token={this.props.link_token} 
                                accessToken={this.state.access_token} 
                                getAccessToken={this.getAccessToken} 
                                account_type={this.props.match.params.account_type}
                                update_progress={this.updateProgress}
                                next_page={this.state.next_page}
                                button_text={this.state.done_connecting ? 'Connect another' : 'Connect'}
                            /> 
                        }
                        <div className='signup-alternative'>
                            {this.state.manual_option ? 
                            <p onClick={this.handleClick} className='blue-alt-option'>Connect account instead</p> : 
                            <p onClick={this.handleClick} className='blue-alt-option'>No thanks, I'll enter it manually</p>
                            }
                        </div>

                    </div>

                    {/* <button className='continue' onClick={this.updateProgress}>CONNECT</button> */}
                    <div className='nav_circle right_side'>
                        {this.state.done_connecting & this.state.manual_option !== false ? 
                            <NavigateCircle 
                                direction='right' 
                                nav_page={this.state.next_page} 
                                go_forward={this.go_forward}>
                            </NavigateCircle>
                            :
                            null
                        }
                    </div>
                    {
                        this.state.adding_loan ? 
                        <BottomModal 
                            setShowModal={this.state.adding_loan} 
                            toggle_adding_loan={this.toggle_adding_loan} 
                            content={<AddDebtPopup
                                type='Loan'
                                account_type={this.props.match.params.account_type}
                                toggle_adding_loan={this.toggle_adding_loan}
                                add_debt={this.add_debt} 
                            />}
                        /> : 
                        null
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(ConnectAccounts)