import React, { Component } from 'react';
// import "./login.css";
import axios from 'axios';
import {withRouter, Link} from 'react-router-dom';
import history from '../../history.js'
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import EnteredLoansContainer from "../entered_loans_container/entered_loans_container.component";



class ManualEntryHandler extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            clickable: '',
            emergency_fund_amount: '',
            monthly_expenses: '',
            errorMessage: null
        }
        // this.handleSubmit = this.handleSubmit.bind(this);
    }           

    componentDidMount() {
        console.log(this.props.accounts);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.props.extendState(this.state);
        }

        // if any of the fields have changed, update the state
        if (this.props.account_type === 'checking') {
            console.log(this.state)
            if (this.state.emergency_fund_amount !== prevState.emergency_fund_amount
                | this.state.monthly_expenses!== prevState.monthly_expenses) 

             {
                if (this.state.emergency_fund_amount !== '' & this.state.monthly_expenses !== "") {
                    this.props.set_clickable('clickable')
                }
                else if (this.state.emergency_fund_amount === '' | this.state.monthly_expenses === "") {
                    this.props.set_clickable('')
                }
            }
        }

    }

    renderEmergencyFund() {
        return (
            <div className='container'>
                <div className='signup-label'>
                    <label>Current Savings</label>
                </div>
                <div className='input-field'>
                    <input  
                        className="form-control signup-input" 
                        required type='number'  
                        value={this.state.emergency_fund_amount} 
                        placeholder='$'
                        onChange={(event) => this.setState({
                            emergency_fund_amount: event.target.value
                        })}>
                    </input>
                </div> 
                <div className='signup-label'>
                    <label>Monthly Expenses</label>
                </div>
                <div className='input-field'>
                    <input  
                        className="form-control signup-input" 
                        required type='number'  
                        placeholder='$'
                        value={this.state.monthly_expenses} 
                        onChange={(event) => this.setState({
                            monthly_expenses: event.target.value
                        })}>
                    </input>
                </div>
            </div>
        );
    }

    renderStudentLoans() {
        return (
            <div className='container'>
                <EnteredLoansContainer
                    toggle_adding_loan={this.props.toggle_adding_loan}
                    set_clickable={this.props.set_clickable}
                    accounts={this.props.student_loan}
                    account_type={this.props.account_type}
                    remove_account={this.props.remove_account}

                >
                </EnteredLoansContainer>
            </div>
        );
    }

    renderCreditCardDebt() {
        return (
            <div className='container'>
                <EnteredLoansContainer
                    toggle_adding_loan={this.props.toggle_adding_loan}
                    set_clickable={this.props.set_clickable}
                    accounts={this.props.credit_card_debt}
                    account_type={this.props.account_type}
                    remove_account={this.props.remove_account}
                >
                </EnteredLoansContainer>
            </div>
        );
    }

 render() {
        return (
            <>
                {this.props.account_type === 'checking' ? this.renderEmergencyFund() : null} 
                {this.props.account_type === 'student_loan' ? this.renderStudentLoans() : null} 
                {this.props.account_type === 'credit_card_debt' ? this.renderCreditCardDebt() : null} 

            </>
        );
    }
}

export default withRouter(ManualEntryHandler)
