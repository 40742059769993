import React, { Component } from 'react';
import "./continue_button.css"
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import history from '../../history.js'


class Button extends Component {
    handleClick = () => {
        if (this.props.continue_action !== undefined) {
            console.log('next page in handle click', this.props.next_page)
            
            if (this.props.current_selections !== undefined) {
                // this is for the account selections action, shouldn't hardcode here
                const account_selections = this.props.current_selections
                this.props.continue_action(account_selections, () => {
                })
            }
            else {
                this.props.continue_action() 
            }

        }
        // else {
        //     history.push(this.props.next_page)
        //     console.log('going to: ', this.props.next_page)

        // }
        
    }

    render() {
        return (
            <div className='continue_button'>
                <Link to={this.props.next_page}>
                    <button className={`continue ${this.props.clickable}`} onClick={this.handleClick}>
                        {this.props.button_text === undefined ? "Continue" : this.props.button_text}
                    </button> 
                </Link>
            </div>
        )
    }
}

export default withRouter(Button)