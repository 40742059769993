import React, { Component } from 'react';
import "./emergency_fund.css";
import { withRouter, Link} from "react-router-dom";
import Navbar from "../navbar/navbar.component.js"
import LoadingScreen from "../loading_screen/loading_screen.component";
import axios from "axios"
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import { BsLock, BsChevronRight, BsBarChart} from 'react-icons/bs';
import BubbleCard from "../bubble_card/bubble_card.component";
import history from '../../history.js'

let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}

class EmergencyFund extends Component {
    constructor(props) {
        super(props)
        this.state = {
            monthly_expenses: null, 
            emergency_fund_target: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount = async() => {
        const res = await axios.get(URL + '/main/monthly_expenses', 
            {withCredentials: true}  
        );

        const monthly_expenses = res.data.monthly_expenses;
        const emergency_fund_months = res.data.emergency_fund_months


        this.setState({
            monthly_expenses: monthly_expenses, 
            emergency_fund_months: emergency_fund_months, 
            emergency_fund_target: monthly_expenses * emergency_fund_months
        })
    }

    handleSubmit = async(event) => {
        event.preventDefault();

        // create User Object with information from form/state
        const updates = {
            monthly_expenses: this.state.monthly_expenses,
            emergency_fund_months: this.state.emergency_fund_months
        }

        // store in database (will prob have to change localhost to something else)
        await axios.put(URL + '/main/emergency_fund', updates, {withCredentials: true})
        await axios.get(URL + '/roundup/roundup',{withCredentials: true})
        
        this.setState({emergency_fund_target: this.state.monthly_expenses * this.state.emergency_fund_months})
        alert('Emergency fund target updated!')
    }

    render() {
        return (
            this.state.monthly_expenses === null?
            <LoadingScreen></LoadingScreen> :
            <div className='full-container'>
                <ColorbarHeader
                    header_text="Emergency Fund"
                    subheader_text={`Your target: $${this.state.emergency_fund_target}`}         
                />
                <div className='container with-navbar'>
                    <p className='nodiv' style={{fontSize: '1.2em'}}>Why keep an emergency fund?</p>
                    <ol>
                        <li>If you lose your job, you can cover your expenses for a few months while you find a new one.</li>
                        <li>You'll probably be able to cover an unexpected medical expense like a broken wrist.</li>
                        <li>You could take time off to care for a loved one without worrying about money.</li>
                    </ol>
                    <p className='center' style={{fontSize: '1.2em'}}>Your Goal</p>

                    <form id="signup-form" onSubmit={this.handleSubmit}>
                        <div id="signup-container emergency-fund-edit">
                            <div className='signup-label'>
                                <label>Monthly Expenses ($):</label>
                            </div>
                            <div className='input-field'>
                                <input className="form-control signup-input" required value={this.state.monthly_expenses} onChange={(event) => {this.setState({monthly_expenses: event.target.value})}}></input>
                            </div>
                            <div className='signup-label'>
                                <label className="signup-label"># of Months:</label>
                            </div>
                            <div className='input-field'>
                                <input className="form-control" required value={this.state.emergency_fund_months} onChange={(event) => {this.setState({emergency_fund_months: event.target.value})}}></input>
                            </div>
                            <div className='signup-label'>
                                <label style={{width: '90%', textAlign: 'center', margin: '3%'}}>Your goal: ${this.state.emergency_fund_months * this.state.monthly_expenses} (${this.state.monthly_expenses} * {this.state.emergency_fund_months} months)</label>
                            </div>

                            <div className='centered-button'>
                                <button type="submit" className="continue clickable">Update goal</button>
                            </div>
                        </div>
                    </form>
                </div>
                <Navbar
                    goals_class={this.props.goals_class}
                    checklist_class={this.props.checklist_class}
                    feedback_class={this.props.feedback_class}
                    profile_class={this.props.profile_class}
                    set_active_navbar={this.props.set_active_navbar}

                />
            </div>
        );
    }
}

export default withRouter(EmergencyFund)
