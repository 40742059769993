import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, withRouter } from 'react-router-dom';


function PrivateRoute({ children, auth, state, ...rest }) { 
    useEffect(() => {
        console.log('User auth:', auth)
        console.log('current state:', state)
    })
  return (
    <Route
      {...rest}
      render={() => {
        return auth === true ? (
          children
        ) : (
          <Redirect to="/login"/>
        );
      }}
    />
  );
}

export default withRouter(PrivateRoute);