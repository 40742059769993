import React, { Component } from 'react';
import {withRouter } from "react-router-dom";
import "./current_goal_progress.css"


class CurrentGoalProgress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bar: null
        }
    }

    create_rotation = () => {
        const rotation = 45 + this.props.progress * 1.8
        const progress_num = rotation.toString();
        return progress_num + 'deg' 
    }
    
    render() {
        const style = { "--rotation": this.create_rotation() } 
        return (
            <div className='progress'>
                <div className='barOverflow'>
                    <div className='bar' style={style}></div>
                </div>
                <p className='progress-label'>{`${this.props.progress}%`}</p>
            </div>
        )
    }
}

export default withRouter(CurrentGoalProgress)