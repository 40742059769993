import React, { Component } from 'react';
import "./loading_screen.css"

export default class LoadingScreen extends Component {
    render() {
        return (
            <div className='loader-container'>
                <div className='spinner'></div>
            </div>
            // <h1 className="loading">Loading</h1>
        )
    }
}