import React, { Component } from "react";
import "./App.css";
import { Router, Route, Link, withRouter, Switch, Redirect} from "react-router-dom";
import axios from "axios"
import Button from "./components/continue_button/continue_button.component";
import RoundupItem from "./components/roundup/roundup_item.component.js";
import Signup from "./components/signup/signup.component.js";
import Login from "./components/login/login.component.js";
import BigTextTransition from "./components/big_text_transition/big_text_transition.component";
import SelectAccounts from "./components/select_accounts/select_accounts.component";
import ConnectAccounts from "./components/connect_accounts/connect_accounts.component"
import ColorbarTransition from "./components/colorbar_transition/colorbar_transition.component"
import Checklist from "./components/checklist/checklist.component"
import TodoHelp from "./components/todo_help/todo_help.component"
import LoadingScreen from "./components/loading_screen/loading_screen.component";
import IncomeInfo from "./components/income_info/income_info.component"
import Settings from "./components/settings/settings.component"
import Navbar from "./components/navbar/navbar.component"
import Feedback from "./components/feedback/feedback.component"
import Goals from "./components/goals/goals.component"
import EmergencyFund from "./components/emergency_fund/emergency_fund.component"
import PrivateRoute from "./components/private_route/private_route.component"
import history from './history.js'
import ColorbarHeader from "./components/colorbar_header/colorbar_header.component";
import LandingPage from "./components/landing_page/landing_page.component";
import compound_interest from './assets/compound_interest.png'
import CurrentGoalProgress from "./components/current_goal_progress/current_goal_progress.component";
import SwipeToHide from "./components/swipe_to_hide/swipe_to_hide.component";
import ReactGA from 'react-ga';
// const TRACKING_ID = "UA-220438183-5"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);

let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
  //https://stackoverflow.com/questions/71889130/render-com-httponly-cookie-not-being-set-in-browser-storage-when-doing-res-cook
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            access_token: null, 
            accounts_position: 0, 
            accounts_selected: [
                // 'checking', 
                // 'savings', 
                // 'four_oh_one', 
                // 'ira', 
                // 'loan'
            ],
            connect_accounts_progress: 0,
            is_checking_login: true,  
            logged_in: true, 
            roundup_items: null,
            roundup_items_summary: null,
            roundup_position: 0, 
            roundup_loaded: false, 
            checklist_loaded: false, 
            goals_class: 'active',
            checklist_class: '',
            feedback_class: '',
            profile_class: '',

        }
        this.set_accounts_selected = this.set_accounts_selected.bind(this)
        this.remove_visited_page_from_state = this.remove_visited_page_from_state.bind(this)
        this.increase_page_position = this.increase_page_position.bind(this)
        this.decrease_page_position = this.decrease_page_position.bind(this)
        this.createLinkToken = this.createLinkToken.bind(this)
        this.set_checklist_loaded = this.set_checklist_loaded.bind(this)
        this.log_user_in = this.log_user_in.bind(this)
        this.clear_user_info = this.clear_user_info.bind(this)
        this.set_active_navbar = this.set_active_navbar.bind(this)
        this.extend_state = this.extend_state.bind(this)
    
    }


    createLinkToken = async (products) => {
        console.log(URL + '/plaid/create_link_token')
        const res = await axios.post(URL + '/plaid/create_link_token', 
            {products: products},
            {withCredentials: true}  // send the requested products in the body as an array
        );
        console.log(res.data)
        const data = res.data.link_token
        this.setState({ token: data }, () => {console.log('link token', data)})
    }

    get_login_status = async () => {
        const res = await axios.get(URL + '/get_session', {withCredentials: true})
        return res.data
    }

    check_if_logged_in = async () => {
        const login_status = await this.get_login_status()
        this.setState({
            logged_in: login_status, 
            is_checking_login: false
        })
    }

    log_user_in = async(userLogin) => {
        await axios.post(URL + '/login', userLogin, {withCredentials: true}).then(response => {  
            this.setState({logged_in: true}, () => { 
                console.log('logged in succesfully', this.state)
                history.push('/income_info')
        })
            
            })
        .catch((error) => {
                alert(error.response.data.error)
        });
    }

    componentDidMount(){
        // ReactGA.pageview(window.location.pathname + window.location.search);
        this.check_if_logged_in()
        console.log('entire app mounted')
    }  

    componentDidUpdate(prevProps, prevState){
        if (prevState.logged_in !== this.state.logged_in) 
        // & this.state.logged_in===false) 
        {
            this.check_if_logged_in()
        }
    }

    set_accounts_selected = (accounts_selected) => {
        let filtered_accounts = []
        console.log(accounts_selected)
        for (let key in accounts_selected) {
            if (accounts_selected[key] === true) {
                filtered_accounts.push(key)
            }
        }
        console.log(filtered_accounts)
        this.setState({accounts_selected: filtered_accounts})
        return
    }


    update_progress_bar = () => {
        this.setState(prevState => {
            let new_state = Object.assign({}, prevState);
            let new_val = new_state['connect_accounts_progress']
            const num_accounts =  this.state.accounts_selected.length
            const interval = 100 / num_accounts
            new_val += interval  // TODO set this to the right interval. might get thrown off by merging accoutns (ex: my roth is same provider as 401k)
            return {'connect_accounts_progress' : new_val};                                
        }, () => console.log(this.state))   //, () => console.log(this.state) 
    }

    get_next_page = (url, page_collection, position, end_dest) => {  
        console.log('getting next page for', position)  
        const pages = this.state[page_collection]
        if (position+1 >= pages.length) {
            return end_dest
        }
        else {
            let next_page_extension;
            if (page_collection === 'roundup_items') {
                console.log('debug: ', pages, position+1)
                next_page_extension = pages[position+1].item_name
            }
            else {
                next_page_extension = pages[position+1]
            }
            const next_page = `/${url}/${next_page_extension}`
            return next_page
        }
    }

    increase_page_position = (pos_state_variable) => {
        this.setState((prev_state) => {
            const new_pos = prev_state[pos_state_variable] + 1;
            return {[pos_state_variable]: new_pos}
        }, () => console.log(`increased ${pos_state_variable} position to: `, this.state[pos_state_variable]))
    }

    decrease_page_position = (pos_state_variable) => {
        this.setState((prev_state) => {
            const new_pos = prev_state[pos_state_variable] - 1;
            return {[pos_state_variable]: new_pos}
        }, () => console.log('decreased roundup position to: ', this.state[pos_state_variable]))
    }

    remove_visited_page_from_state = (state_array, next_account) => {  
        console.log('removing visited page from state')
        this.setState(prevState => {
            console.log(prevState, next_account)
            let new_state = Object.assign({}, prevState);
            delete new_state[state_array][next_account] 
        }, () => console.log(this.state))
    }

    create_roundup_items_summary = (roundup_items) => {
        // const roundup_items = this.state.roundup_items
        const e_fund = (roundup_items[0] !== undefined) ? roundup_items[0].amount : 0
        const high_interest_debt = (roundup_items[1] !== undefined) ? roundup_items[1].amount : 0
        const moderate_interest_debt = (roundup_items[3] !== undefined) ? roundup_items[3].amount : 0
        const roth = (roundup_items[4] !== undefined) ? roundup_items[4].amount : 0
        const four_oh_one_k = (roundup_items[5] !== undefined) ? roundup_items[5].amount : 0

        const emergency_fund_amount = Math.round((e_fund) / 100) * 100 // parts 1 and 2
        const debt_amount =  Math.round((high_interest_debt + moderate_interest_debt) / 100) * 100 // high and moderate
        const retirement_amount = Math.round((roth + four_oh_one_k) / 100) * 100

        let roundup_items_summary = [
            {'item_name': 'emergency_fund', 'amount': emergency_fund_amount},
            {'item_name': 'retirement', 'amount': retirement_amount},
            {'item_name': 'loans', 'amount': debt_amount}
        ]
        roundup_items_summary = roundup_items_summary.filter(item => item.amount !== 0)
        return roundup_items_summary
    }

    do_roundup_process = async() => {
        // api call to set the roundup items state
        const res = await axios.get(URL + '/roundup/roundup', 
            {withCredentials: true}  
        );
        const roundup_items_summary = await this.create_roundup_items_summary(res.data)
        let roundup_list_items = roundup_items_summary.map((item) => {
            const amount = item.amount
            const roundup_header_text_map = 
            {
                'emergency_fund': `Build up a $${amount} emergency fund`,  
                'retirement': `Save $${amount} for retirement`, 
                'loans': `Put $${amount} towards your loans`
            }
            return <li>{roundup_header_text_map[item.item_name]}</li>
        })
        this.setState({roundup_items_summary: roundup_list_items}, () => {
            this.setState({roundup_items: roundup_items_summary}, () => {
                this.setState({roundup_loaded: true})
            }, () => console.log('finished_roundup process', this.state))
        })
    
    }

    do_checklist_process = async() => {
        // api call to set the checklist items state
        const res = await axios.get(URL + '/roundup/generate_checklist', 
            {withCredentials: true}  
        )
        this.setState({checklist_items: res.data}, () => { 
            console.log(res.data)
            this.setState({checklist_loaded: true})
        })
    }

    set_checklist_loaded = async() => {
        this.setState({checklist_loaded: true})
    }

    clear_user_info = async() => {
        const res = await axios.delete(URL + '/main/redo_setup', 
            {withCredentials: true}  
        )
    }

    set_active_navbar = (item_class) => {
        let new_state = {
            checklist_class: '',
            goals_class: '',
            feedback_class: '',
            profile_class: '',
        }
        new_state[item_class] = 'active'
        this.setState(new_state, () => console.log(this.state))
    }

    extend_state = (object) => {
        this.setState(
            object
        )
    }

    render() {
        if (this.state.is_checking_login === true) { return <LoadingScreen></LoadingScreen> }
        
        return (
            <main>
                
                <Switch>
                    <Route exact path='/'>{ this.state.logged_in ? <Redirect to='/checklist'></Redirect> : <LandingPage></LandingPage>   }</Route>
                    <Route path="/signup"><Signup></Signup></Route>    
                    <Route path="/login"><Login log_user_in={this.log_user_in}></Login></Route>
                    <Route exact path="/setup">
                        <BigTextTransition 
                            transition_text="Welcome to Envoy! Now let’s figure out what you need to get started." 
                            next_page="income_info"
                        /> 
                    </Route>
                    <PrivateRoute exact path="/income_info" auth={this.state.logged_in} state={this.state}>
                        <IncomeInfo/>    
                    </PrivateRoute>
                    <Route exact path="/select_accounts"><SelectAccounts set_accounts_selected={this.set_accounts_selected}/></Route>
                    <Route path="/connect_accounts/:account_type">
                        <ConnectAccounts 
                            link_token={this.state.token} 
                            accessToken={this.state.access_token}  
                            getAccessToken={this.getAccessToken} 
                            connect_accounts_progress={this.state.connect_accounts_progress}
                            update_progress_bar={this.update_progress_bar}
                            create_link_token={this.createLinkToken}
                            increase_page_position={this.increase_page_position}
                            decrease_page_position={this.decrease_page_position}
                            accounts_position={this.state.accounts_position}
                            get_next_page={this.get_next_page}
                            page_collection="accounts_selected"
                            url="connect_accounts"
                        />
                    </Route>
                    <Route exact path="/connect_accounts">
                        <ColorbarTransition 
                            header_text="Great! Now let’s connect those accounts" 
                            body_text="We’ll use them to determine your current level of saving, spending, and use those to build your personal financial plan.
                                        None of your account information will be stored on our servers and we will never sell your data." 
                            next_page={`/connect_accounts/${this.state.accounts_selected[this.state.accounts_position]}`}
                            url="connect_accounts"
                            accounts_position={this.state.accounts_position}
                        >
                        </ColorbarTransition>
                    </Route> 
                    <PrivateRoute path="/accounts_connected" auth={this.state.logged_in}>
                        <ColorbarTransition 
                            header_text="Awesome work! You've completed the setup process" 
                            body_text="Now we'll analyze your situation and generate a financial plan just for you. " 
                            next_page="/roundup"
                            continue_action={this.do_roundup_process}
                            celebration='yes'
                        />
                    </PrivateRoute>
                    <PrivateRoute exact path="/roundup" auth={this.state.logged_in}>
                        {this.state.roundup_loaded ?
                        <ColorbarTransition 
                            header_text={`Here’s what you can accomplish during ${(new Date()).getFullYear()}...`}
                            body_text={
                                <div>
                                    <ol>
                                        {this.state.roundup_items_summary}
                                    </ol>
                                   {(new Date()).getMonth() > 9 ? 
                                    <p>Don't worry if these numbers seem low, you'll be able to do more once the new year starts!</p> : 
                                    null
                                   }
                                    <p></p>
                                </div>
                            }
                                    
                            next_page={this.state.roundup_items === null ? 'blah' : `/roundup/${this.state.roundup_items[this.state.roundup_position].item_name}`}>
                        </ColorbarTransition> :
                        <LoadingScreen/>
                        }
                    </PrivateRoute>
                    <PrivateRoute path="/roundup/:roundup_item" auth={this.state.logged_in}>
                        <RoundupItem
                            remove_visited_page_from_state={this.remove_visited_page_from_state}
                            get_next_page={this.get_next_page}
                            url="roundup"
                            page_collection="roundup_items"
                            roundup_item={this.state.roundup_items === null ? null : this.state.roundup_items[this.state.roundup_position]}
                            roundup_position={this.state.roundup_position}
                            update_roundup_position={this.increase_page_position}
                            decrease_roundup_position={this.decrease_page_position}
                        >
                        </RoundupItem>
                    </PrivateRoute>
                    <PrivateRoute path="/checklist_transition" auth={this.state.logged_in}>
                        <ColorbarTransition 
                            header_text={`"Now what?"`}
                            body_text="Good question. We've generated a simple checklist for you based on financial best practices. Your checklist will update after each paycheck so you can reach your goals for the year!" 
                            next_page="/checklist"
                            continue_action={this.do_checklist_process}
                        />
                    </PrivateRoute>
                    <PrivateRoute exact path="/checklist" auth={this.state.logged_in}>
                        <Checklist 
                            checklist_items={this.state.checklist_items} 
                            checklist_loaded={this.state.checklist_loaded}
                            first_name={this.state.first_name}
                            goals_class={this.state.goals_class}
                            checklist_class={this.state.checklist_class}
                            feedback_class={this.state.feedback_class}
                            profile_class={this.state.profile_class}
                            set_active_navbar={this.set_active_navbar}
                            extend_state={this.extend_state}
        
                        /> 
                    </PrivateRoute>
                    <PrivateRoute path="/checklist_help/:item" auth={this.state.logged_in}>
                        <TodoHelp
                            goals_class={this.state.goals_class}
                            checklist_class={this.state.checklist_class}
                            feedback_class={this.state.feedback_class}
                            profile_class={this.state.profile_class}
                            set_active_navbar={this.set_active_navbar}
                        />
                    </PrivateRoute>
                    <PrivateRoute path="/profile" auth={this.state.logged_in}>
                        <Settings  
                            first_name={this.state.first_name}
                            goals_class={this.state.goals_class}
                            checklist_class={this.state.checklist_class}
                            feedback_class={this.state.feedback_class}
                            profile_class={this.state.profile_class}
                            set_active_navbar={this.set_active_navbar}
                        > 
                        
                        </Settings>
                    </PrivateRoute>
                    <PrivateRoute path="/tos" auth={this.state.logged_in}>
                        <ColorbarHeader header_text="Privacy Policy"></ColorbarHeader>
                        <h4 style={{textAlign: 'center'}}>User Credentials</h4>
                            <p className="nodiv">We do not store your institutional login information on our servers. We store read-only access tokens to any accounts you've connected in order to track your balances. The tokens do not allow anyone to take actions within your accounts.</p>
                        <h4 style={{textAlign: 'center'}}>Data Usage</h4>
                            <p className="nodiv">We do not sell user data to third parties. All data usage will be for marketing purposes only and will never identify any individual users without their consent.</p>
                        <h4 style={{textAlign: 'center'}}>Financial Advice Disclaimer</h4>
                        <p className="nodiv" style={{marginBottom: '20%'}}>
                            None of the creators of this site are certified financial planner/advisors,  
                            certified financial analysts, economists, CPAs, accountants, or lawyers.
                            This advice has been compiled based on various resources and best practices around the internet. 
                            The contents on this site are for informational and entertainment purposes only and does not constitute 
                            financial, accounting, or legal advice. By using this site, you agree to hold its owners harmless from any 
                            ramifications, financial or otherwise, that occur to you as a result of acting on information found 
                            on this site.
                        </p>
            
                        <Navbar
                            goals_class={this.state.goals_class}
                            checklist_class={this.state.checklist_class}
                            feedback_class={this.state.feedback_class}
                            profile_class={this.state.profile_class}
                        />
                    </PrivateRoute>
                    <PrivateRoute path="/feedback" auth={this.state.logged_in}>
                        <Feedback
                            goals_class={this.state.goals_class}
                            checklist_class={this.state.checklist_class}
                            feedback_class={this.state.feedback_class}
                            profile_class={this.state.profile_class}
                            set_active_navbar={this.set_active_navbar}
                        ></Feedback>
                    </PrivateRoute>
                    <PrivateRoute path="/goals" auth={this.state.logged_in}>
                        <Goals
                            goals_class={this.state.goals_class}
                            checklist_class={this.state.checklist_class}
                            feedback_class={this.state.feedback_class}
                            profile_class={this.state.profile_class}
                            set_active_navbar={this.set_active_navbar}
                        ></Goals>
                    </PrivateRoute>
                    <PrivateRoute path="/emergency_fund" auth={this.state.logged_in}>
                        <EmergencyFund
                            goals_class={this.state.goals_class}
                            checklist_class={this.state.checklist_class}
                            feedback_class={this.state.feedback_class}
                            profile_class={this.state.profile_class}
                            set_active_navbar={this.set_active_navbar}
                        ></EmergencyFund>
                    </PrivateRoute>
                    <PrivateRoute path="/loans" auth={this.state.logged_in}>
                        <div className="full-container">
                            <ColorbarHeader
                                header_text="Loans"                            
                            />
                            <div className="container with-navbar">
                                <p className="nodiv center" style={{fontSize: '1.2em', fontWeight: '500'}}>How should I do it?</p>
                                <p className="">Paying off loans can seem like an impossible task, but with discipline it can be done! Here's our method:</p>
                                <ol style={{fontSize: '1em'}}>
                                    <li>If you have loans above a 5% interest rate, start with the highest interest rate loan and pay it off as fast as possible. Then move onto the next one. </li>
                                    <li>For every other loan you have, just make the minimum payment.</li>
                                </ol>

                                <p className="nodiv center" style={{fontSize: '1.2em'}}>Why does this work?</p>
                                <p className="nodiv">Unfortunately, the same compounding force that works to your advantage when investing your money also applies to loan interest piling up. By focusing on your highest interest rate loan first, then moving down the ladder, you can keep the interest balance from spiraling out of control.</p>
                            </div>
                            <Navbar
                                goals_class={this.state.goals_class}
                                checklist_class={this.state.checklist_class}
                                feedback_class={this.state.feedback_class}
                                profile_class={this.state.profile_class}
                            />
                        </div>
                    </PrivateRoute>
                    <PrivateRoute path="/retirement" auth={this.state.logged_in}>
                        <div className="full-container">
                            <ColorbarHeader
                                header_text="Retirement"                            
                            />
                            <div className="container with-navbar">
                                <p className="">You probably know that you should be saving for retirement. Here's what will convince you to actually do it. </p>
                                <img src={compound_interest} style={{border: '1px solid black', margin: '2%'}}></img>
                                <p className="">Time is on your side. If you start saving for retirement in 10 years, you'll potentially miss out on $500,000 in growth. This is only possible because of compounding. Your investments will typically grow about 7% a year. The more years your money has to grow, the bigger difference that 7% makes! 7% of $1000 is $70, but 7% of $100,000 is $7000!</p>
                            </div>

                            <Navbar
                                goals_class={this.state.goals_class}
                                checklist_class={this.state.checklist_class}
                                feedback_class={this.state.feedback_class}
                                profile_class={this.state.profile_class}
                            />

                        </div>
                    </PrivateRoute>
                    <PrivateRoute path='/redo-setup' auth={this.state.logged_in}>
                        <h1 className="transition">Are you sure you want to redo the setup process? This will clear out any data you've input so far.</h1>
                        {/* <Button button_text="No, take me back!" continue_action={this.clear_user_info} next_page="/setup"></Button> */}
                        <Button button_text="Yes, I'm sure!" continue_action={this.clear_user_info} next_page="/setup"></Button>
                    </PrivateRoute>
                    <Route path='/loading'><LoadingScreen></LoadingScreen></Route>
                    <Route path='/swipe'><SwipeToHide></SwipeToHide></Route>
                    <Route><p>doesn't exist!</p></Route>
                </Switch>
            </main>
        );
    }
}

export default App;

