import { PlaidLink } from "react-plaid-link";
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import history from '../../history.js'
import "./link.css"

class CustomPlaidLink extends Component {
    componentDidMount() {
        console.log('plaidlink mounted for account type: ', this.props.account_type)

    }
   onExit = (error, metadata) => {console.log('onExit', error, metadata)}
  
   onEvent = (eventName, metadata) => {
    console.log('onEvent', eventName, metadata);
  }
  
   onSuccess = (token, metadata) =>{
    console.log('Successfully connected', token);
    this.props.getAccessToken(token, [this.props.account_type], this.props.next_page)
        // this.props.update_progress()
  }

render () {
    if (!this.props.token) {
        console.log('no token')
            return null;
        }
    return (
        <PlaidLink
                className="continue CustomPlaidLink clickable"
                token={this.props.token ? this.props.token : ''}
                onExit={this.onExit}
                onSuccess={this.onSuccess}
                onEvent={this.onEvent}
            >
            {this.props.button_text}
        </PlaidLink>
    );
    }
}

export default withRouter(CustomPlaidLink);