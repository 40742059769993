import React, { Component, useState } from 'react';
import "./account_select_button.css"
import {withRouter } from "react-router-dom";
import history from '../../history.js'



// todo figure out how to best practice to change the color and words of this header
// i think that we just use props and pass in text and a color maybe, then re-render the bar? 
// each page will have its own colorbar component
class AccountSelectButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false
        };
    }

    handleClick = () => {
        const item = this.props.item
        this.props.add_selection(item)  // set item state in select_accounts_component
        this.setState({clicked: !this.state.clicked})
    }

    render() {
        let button_class = this.state.clicked ? "selected" : ""
        return (
            <div className='account_selection'>
                <button className={`account_selection ${button_class}`} onClick={this.handleClick}>{this.props.text}</button> 
            </div>
        )
    }
}

export default withRouter(AccountSelectButton)