import React, { Component } from 'react';
import "./todo_item.css"
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import { withRouter, Link } from "react-router-dom";
import history from '../../history.js';
import axios from "axios"
 
class TodoItem extends Component {
    constructor(props){
        super(props)
        this.state = {
            type: this.props.item.type, 
            amount: this.props.item.amount, 
            debt_type: this.props.item.debt_type, 
            interest_rate: Math.floor(this.props.item.interest_rate),
            institution_name: this.props.item.institution_name, 
            id: this.props.item._id, 
            checked: this.props.item.checked
        }

        this.text_map = {
            'emergency_fund': `Save $${this.state.amount} in your emergency fund`,  // figure out where we need to store the amounts, are they passed in? 
            'roth': `Invest $${this.state.amount} in your Roth IRA`,  
            'roth_account': `Open a Roth IRA`,              
            'credit': `Pay off $${this.state.amount} on your ${this.state.interest_rate}% credit card debt (${this.state.institution_name})`,
            'student': `Pay off $${this.state.amount} on your ${this.state.interest_rate}% student loan (${this.state.institution_name})`,
            'loans': `Put $${this.state.amount} towards your ${this.state.debt_type}`, 
            '401k': `Set your 401k contribution to ${this.state.amount}%` // this should really just be a % set initially. calculate this
        }
    } 

    handleCheck = async() => {
        this.setState(prevState => ({checked: !prevState.checked}), () => {
            const todo_data = {
                amount: this.state.amount, 
                checked: this.state.checked
            }
            const res = axios.put(`http://localhost:5000/checklist/update_todo/${this.props.item._id}`, 
                {withCredentials: true, 
                todo_data
                }
            )
        }
        
        )
    }


    render() {
        return (
            <div className='todo_item'>
                <input type='checkbox' className='todo' onChange={this.handleCheck} checked={this.state.checked}/> 
                <label className='todo-item'>
                    <Link to={
                        {pathname:`/checklist_help/${this.state.type}`, 
                        amount: this.state.amount, 
                        debt_type: this.state.debt_type,
                        interest_rate: this.state.interest_rate
                    }
                    }>
                        {this.text_map[this.state.type]}
                    </Link>
                </label>
            </div>
        )
    }
}

export default withRouter(TodoItem)