import React, { useRef } from "react";
import "./modal.css";
import ReactDom from "react-dom";

export const BottomModal = ({ setShowModal, toggle_adding_loan, content}) => {
  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      toggle_adding_loan(false)
    }
  };


  //render the modal JSX in the portal div.

  return ReactDom.createPortal(
    <div className="modal-container" ref={modalRef} onClick={closeModal}>
      <div className="modal" >
        {content}
      </div>
    </div>,
    document.getElementById("portal")
  );
};