import React, { Component } from 'react';
import "./income_info.css"
import Button from "../continue_button/continue_button.component";
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import { withRouter, Link } from "react-router-dom";
import TodoItem from "../todo_item/todo_item.component.js"
import axios from "axios"
import history from '../../history.js'

let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}

class IncomeInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true, 
            pay_frequency: 24, 
            exempt: true, 
            pay_type: 'salary'
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        console.log('mounted income info')
    }

    handleChange(e){
        console.log(e.target.value)
        if (e.target.value === 'hourly') {
            this.setState({'exempt': false, pay_type: 'hourly'}, () => console.log(this.state))
        }
        else if (e.target.value === 'salary') {
            this.setState({'exempt': true, pay_type: 'salary'}, () => console.log(this.state))
        }
        else {
            this.setState({[e.target.id]: Number(e.target.value)}, () => console.log(this.state))
        }
    }

    calculate_hourly_annual_income = () => {
        if (this.state.hourly_rate === undefined | this.state.hours_per_week === undefined) {
            return ''
        }

        const hourly_rate = this.state.hourly_rate
        const hours_per_week = this.state.hours_per_week

        const weekly_pay = hourly_rate * hours_per_week
        const annual_income = weekly_pay * 52
        return annual_income
    }

    handleSubmit(event) {
        event.preventDefault();
        const submission = {
            annual_income: this.state.annual_income, 
            pay_frequency: Number(this.state.pay_frequency)
        }

        axios.put(URL + '/setup/income', submission, {withCredentials: true}).then(response => { 
                console.log('success'); 
                history.push('/select_accounts')
             })
        .catch((error) => {
                alert(error)
        });
    }

    set_pay_frequency = (event) => {
        this.setState({pay_frequency: event.target.value}, () => console.log(this.state))
    }

    render() {
        return (
            <div className='full-container'> 
                <ColorbarHeader header_text="Income Information"/>
                <div className='container'>
                    <p style={{margin: '5%', fontSize: '1.1em'}}>We'll need your income to project your potential savings and build your plan for the year.</p>
                    <form onSubmit={this.handleSubmit} style={{display: 'flex', flexWrap: 'wrap', justifyContent:'center', height: '100%'}}>
                            <div className='same-row'>
                                <label for="exempt" className="signup-label income">Pay Type:</label>
                                <select required className="form-control income-input" id="exempt" onChange={(e) => this.handleChange(e)} value={this.state.pay_type}>
                                    <option selected="selected" value="salary">Salary</option>
                                    <option value="hourly">Hourly</option>
                                </select>
                            </div>

                        
                        {
                            this.state.exempt === false?
                            <div className='hourly-helper'>
                                <div className='heading'>
                                    <p>Hourly Calculator</p>
                                </div>
                                <div className='container'>
                                    <div className='signup-label'>
                                        <label for='hourly_rate' className='signup-label'>Hourly Rate:</label> 
                                    </div>
                                    <div className='input-field'>
                                        <input className='form-control' type='number' id='hourly_rate'  onChange={(e) => this.handleChange(e)}></input>
                                    </div>
                                    <div className='signup-label'>
                                        <label for='hours_per_week' className='signup-label'>Hours Per Week:</label> 
                                    </div>
                                    <div className='input-field'>
                                        <input className='form-control' type='number' id='hours_per_week' onChange={(e) => this.handleChange(e)}></input>
                                    </div>
                                    <div className='est-annual-income'>
                                        <p>Estimated annual income: ${this.calculate_hourly_annual_income()}</p>
                                    </div>
                                </div>
                        </div>: 
                        null

                        }
                                <div className='same-row'>
                                    <label for="pay_frequency" className="signup-label income">Pay Frequency:</label>                                    
                                    <select required className="form-control income-input" id="pay_frequency" onChange={this.set_pay_frequency} value={this.state.pay_frequency}>
                                        <option selected="selected" value="24">Twice a Month</option>
                                        <option value="12">Once a Month</option>
                                        <option value="52">Weekly</option>
                                    </select>
                                </div>
                                <div className='signup-label'>
                                    <label for='annual_income' className="signup-label income">Annual Income</label> 
                                </div>
                                <div className='input-field'>
                                    <input required className='form-control income-input' type='number' id='annual_income' placeholder='$' onChange={(e) => this.handleChange(e)}></input>
                                </div>

                                <div className='centered-button'>
                                    <button type="submit" className="continue clickable">
                                        Continue
                                    </button>
                                </div>
                    </form>
                </div>
                
            </div>
        )
    }
}

export default withRouter(IncomeInfo)