import React from 'react';
import { useGesture, useDrag } from '@use-gesture/react';

const SwipeToHide = () => {
  const [hide, setHide] = React.useState(false);
  const bind = useDrag(({ down, delta: [yDelta], direction: [yDir], velocity }) => {
    console.log(down, yDelta, yDir, velocity);

    if (down && yDelta > 1 && yDir > 0 && velocity[1] > 0.5) {
      setHide(true);
      console.log('hide');
    }
  });


  return (
    <div {...bind()} style={{ display: hide ? 'none' : 'block', 'touchAction': 'none' }}>
      Swipe to hide
    </div>
  );
};

export default SwipeToHide;