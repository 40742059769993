import React, { Component } from 'react';
// import "./login.css";
import axios from 'axios';
import {withRouter, Link} from 'react-router-dom';
import history from '../../history.js'
import ColorbarHeader from "../colorbar_header/colorbar_header.component";


class AddDebtPopup extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            name: "",
            amount: "", 
            interest_rate: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    componentDidUpdate = (prevProps, prevState) => {
        const state_items = Object.values(this.state).slice(0, 3)
        const prev_state_items = Object.values(prevState).slice(0, 3)
        console.log(state_items, prev_state_items)
        if (!(this.arraysEqual(state_items, prev_state_items))) {
            if (state_items.every(val => val !== '')) {
                this.setState({
                    clickable: 'clickable'
                }, () => console.log(this.state))
            }
            else {
                this.setState({
                    clickable: ''
                })
            }
        } 
    }
    
    handleSubmit(event)
    {
        event.preventDefault();
    }

    handleClick = () => {
        const new_debt = {
            institution_name: this.state.name, 
            loan_amount: Number(this.state.amount),
            interest_rate: Number(this.state.interest_rate) 
            
        }
        this.props.add_debt(new_debt, this.props.account_type)
        this.props.toggle_adding_loan(false)
    }

    render()
    {
        return (
            <div className = "full-container">
                <div className='container'>
                    <h1>Add a {this.props.account_type === 'student_loan'? 'Loan': 'Debt'}</h1>
                    <form id="signup-form" onSubmit={this.handleSubmit}>
                        <div className='signup-label'>
                            <label>{this.props.account_type === 'student_loan'? 'Loan': 'Debt'} Name</label>
                        </div>
                        <div className='input-field'>
                            <input placeholder='ex: SoFi' className="form-control signup-input" required value={this.state.name} onChange={(event) => this.setState({name: event.target.value})}></input>
                        </div>
                        <div className='signup-label'>
                            <label>Amount</label>
                        </div>
                        <div className='input-field'>
                            <input placeholder='$'
className="form-control signup-input" type='number' required value={this.state.amount} onChange={(event) => this.setState({amount: event.target.value})}></input>
                        </div>
                        <div className='signup-label'>
                            <label>Interest Rate</label>
                        </div>
                        <div className='input-field'>
                            <input 
                                className="form-control signup-input" 
                                type='number' 
                                required 
                                value={this.state.interest_rate} 
                                onChange={(event) => this.setState({interest_rate: event.target.value})}
                                placeholder='For 5%, enter 5 instead of .05'
                            />

                        </div>                    
                        <div className='centered-button'>
                            <button 
                                type="submit" 
                                className={`continue signup ${this.state.clickable}`} 
                                id="signup-button"
                                onClick={this.handleClick}
                            >
                                Add {this.props.account_type === 'student_loan'? 'Loan': 'Debt'}
                            </button>
                        </div>
                    </form>
            </div>

            </div>
        );
    }
}

export default withRouter(AddDebtPopup)
