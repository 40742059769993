import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter, Redirect } from "react-router-dom";
import "./connected_account_box.css"
// import body text dict


class ConnectedAccountBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account_name: this.props.account_name, 
            account_index: this.props.account_index
        };
    } 



    componentDidMount(){
        console.log(this.props)

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.account_name !== prevState.account_name) {
            this.setState({'account_name': this.props.account_name})
        }         

    }

    handleClick = () => {
        const account_index = this.state.account_index;
        this.props.remove_account(account_index, this.props.account_type);
    }


    render() {
        return (
            <div className={`connected_account ${this.props.first} ${this.props.last}`}>
                <div style={{width:'80%'}}>
                    <p className='account_name'>{this.state.account_name}</p>
                    <p className='account_name amount'>${this.props.amount} with {this.props.interest_rate}% interest</p>
                </div>
                <div style={{width: '20%', height: '10%', margin: '0', display: 'flex', alignItems: 'center'}}>
                    <button className='remove' onClick={this.handleClick}>X</button>
                </div>
            </div>
        )
    }
}

export default withRouter(ConnectedAccountBox)