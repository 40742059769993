import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter, Redirect } from "react-router-dom";
import axios from "axios"
// import "./connected_accounts_container.css"
import ConnectedAccountBox  from "../connected_account_box/connected_account_box.component"
// import { update } from '../../models/users.model';
// import body text dict


class EnteredLoansContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account_type: this.props.account_type
        };
    } 


    componentDidMount(){
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.accounts !== prevProps.accounts) {
            if (this.props.accounts.length > 0) {
                this.props.set_clickable('clickable')
            }
            else {
                this.props.set_clickable('')
            }
        }
    }

    get_accounts = async() => {
        console.log('running get accounts')
        const res = await axios.get(`http://localhost:5000/plaid/connected_accounts/${this.state.account_type}`, 
            {withCredentials: true}  // send the requested products in the body as an array
        );
        console.log(res.data)
        this.setState({accounts: res.data}, () =>console.log(this.state) )

    }


    handleClick = () => {
        this.props.toggle_adding_loan(true)
    }


    render() {
        return (
            <div className='connected_accounts_container full-container'> 
                {/* <p style={
                    {textAlign: 'center', 
                    fontSize: '1.2em', 
                    marginTop: '3%', 
                    marginBottom: '3%'}}
                >
                    Loans
                </p> */}
                {this.props.accounts.map((account, index) => 
                    <ConnectedAccountBox 
                        account_name={account.institution_name}
                        amount={account.loan_amount}
                        interest_rate={account.interest_rate}
                        remove_account={this.props.remove_account}
                        account_index={index}
                        account_type={this.props.account_type}
                        key={index}
                        first={index === 0 ? 'first' : ''}
                        last={index === this.props.accounts.length-1? 'last' : ''}
                    />
                )}
                <div onClick={this.handleClick} className='container' style={{marginBottom: '3%'}}>
                    <p className='normal-text' style={{color: 'rgb(130, 155, 219)'}}>
                        + Add {this.props.account_type === 'student_loan'? 'loan': 'debt'}
                    </p>     
                </div>
               
            </div>
        )
    }
}

export default withRouter(EnteredLoansContainer)