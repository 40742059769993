import React, { Component } from 'react';
import "./signup.css";
import axios from 'axios';
import {withRouter, Link} from 'react-router-dom';
import history from '../../history.js'
import ColorbarHeader from "../colorbar_header/colorbar_header.component";

let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}
class Signup extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            first: "",
            last: "",
            email: "",
            password: "", 
            clickable: '',
            errorMessage: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    componentDidUpdate = (prevProps, prevState) => {
        const state_items = Object.values(this.state).slice(0, 4)
        const prev_state_items = Object.values(prevState).slice(0, 4)
        console.log(state_items, prev_state_items)
        if (!(this.arraysEqual(state_items, prev_state_items))) {
            if (state_items.every(val => val !== '')) {
                this.setState({
                    clickable: 'clickable'
                }, () => console.log(this.state))
            }
            else {
                this.setState({
                    clickable: ''
                })
            }
        }
    }
    handleSubmit(event) {
        event.preventDefault();

        // create User Object with information from form/state
        const newUser = {
            firstName: this.state.first,
            lastName: this.state.last,
            email: this.state.email,
            password: this.state.password
        }

        const userLogin = {
            email: this.state.email, 
            password: this.state.password
        }

        // store in database (will prob have to change localhost to something else)
        axios.post(URL + '/signup', newUser, {withCredentials: true}).then(response => { 
             axios.post(URL + '/login', userLogin, {withCredentials: true}).then(response => {
                history.push('/setup');
             })
          }).catch((error) => {
                alert(error.response.data.error)
          });
    }

        handleClick = () => {
            history.push('/login')
        }

    render()
    {
        return (
            <div className = "full-container">
                <ColorbarHeader header_text="Envoy Finance"/>
                <div className="container">
                    <form id="signup-form" onSubmit={this.handleSubmit}>
                        <div className='signup-label'>
                            <label className="signup-label">First Name</label>
                        </div>
                        <div className='input-field'>
                            <input className="form-control signup-input" required value={this.state.first} onChange={(event) => {this.setState({first: event.target.value})}}></input>
                        </div>
                        <div className='signup-label'>
                            <label className="signup-label">Last Name</label>
                        </div>
                        <div className='input-field'>
                            <input className="form-control signup-input" required value={this.state.last} onChange={(event) => this.setState({last: event.target.value})}></input>
                        </div>
                        <div className='signup-label'>
                            <label className="signup-label">Email</label>
                        </div>
                        <div className='input-field'>
                            <input className="form-control signup-input" type='email' required value={this.state.email} onChange={(event) => this.setState({email: event.target.value.toLowerCase()})}></input>
                        </div>
                        <div className='signup-label'>
                            <label className="signup-label">Password</label>
                        </div>
                        <div className='input-field'>
                            <input className="form-control signup-input" required type="password" value={this.state.password} onChange={(event) => this.setState({password: event.target.value})}></input>
                        </div>

                        <p className = "errorMessage">{this.state.errorMessage}</p>
                    
                    <div className='centered-button'>
                        <button type="submit" className={`continue signup ${this.state.clickable}`} id="signup-button">Continue</button>
                        <div className='signup-alternative'><Link to="/login" style={{color: '#4c8bf5'}}>Login Instead</Link></div>
                    </div>
                    
                </form> 
            </div>
                

            </div>
        );
    }
}

export default withRouter(Signup)
