import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter} from "react-router-dom";
import "./navigate_button.css"



class NavigateCircle extends Component {
    render() {
        return (
                <Link to={this.props.nav_page}>
                    <span class={`navigation ${this.props.direction}`} onClick={this.props.go_forward}></span>
                </Link>
        )
    }
}

export default withRouter(NavigateCircle)