import React, { Component } from 'react';
import {withRouter } from "react-router-dom";
import "./progress_bar.css"



class ProgressBar extends Component {
    // progress = 0? or track outside and pass in as prop
              

    create_progress_pct = () => {
        const progress_num = this.props.progress.toString();
        console.log(progress_num)
        return progress_num + '%' 
    }
    render() {
        const style = { "--to-width": this.create_progress_pct()
        , width: this.create_progress_pct() } 
        return (
            <div className='progress_bar_container'>
                <div className='progress_bar'>
                    <div className='progress_bar inside' style={style}></div>
                </div>
            </div>
        )
    }
}

export default withRouter(ProgressBar)