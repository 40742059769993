import React, { Component } from 'react';
import "./settings.css";
import { withRouter, Link} from "react-router-dom";
import Navbar from "../navbar/navbar.component.js"
import LoadingScreen from "../loading_screen/loading_screen.component";
import axios from "axios"
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import { BsLock, BsChevronRight, BsBarChart, BsGear} from 'react-icons/bs';
import BubbleCard from "../bubble_card/bubble_card.component";
import history from '../../history.js'

let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}
class Settings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            first_name: null
        }
    }

    onClick = async() => {
        const res = await axios.delete(URL + '/logout', 
            {withCredentials: true}  
        );
    }

    componentDidMount = async() => {
        // const res = await axios.get(URL + '/user', 
        //     {withCredentials: true}  
        // );

        // const first_name = res.data.firstName;
        // this.setState({first_name: first_name})
    }

    toTOS = async() => {
        history.push('/tos')
    }

    toRoundup = async() => {
        history.push('/goals')
    }

    render() {
        return (
            this.props.first_name === null?
            <LoadingScreen></LoadingScreen> :
            <div className='full-container'>
                <ColorbarHeader header_text={`Welcome, ${this.props.first_name}!`}/>
                <div className='settings-container'>
                    <BubbleCard 
                        text={`${(new Date().getFullYear())} Goals`} 
                        next_page="/goals" 
                        logo={<BsBarChart size={20}/>}
                        go_forward='yessir'
                    />

                    <BubbleCard 
                        text="Redo Setup " 
                        next_page="/redo-setup" 
                        logo={<BsGear size={20}/>}
                        go_forward='yessir'
                    />
                    <BubbleCard 
                        text="Privacy Policy" 
                        next_page="/tos" 
                        logo={<BsLock size={20}/>}
                        go_forward='yessir'

                    />                  

                </div>
                <div className='centered-button'>
                    <Link to="/login">
                        <button onClick={this.onClick} className="continue clickable">Logout</button>
                    </Link>
                </div>
                    <Navbar
                        goals_class={this.props.goals_class}
                        checklist_class={this.props.checklist_class}
                        feedback_class={this.props.feedback_class}
                        profile_class={this.props.profile_class}
                        set_active_navbar={this.props.set_active_navbar}
                    />
            </div>
        );
    }
}

export default withRouter(Settings)
