import React, { Component } from 'react';
import "./goals.css";
import axios from 'axios';
import { withRouter, Link} from "react-router-dom";
import { BsPiggyBank, BsBank, BsUnlock } from 'react-icons/bs';
import Navbar from "../navbar/navbar.component.js"
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import BubbleCard from "../bubble_card/bubble_card.component";
import LoadingScreen from "../loading_screen/loading_screen.component";

let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}

class Goals extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            goals: null
        }
    }

    componentDidMount = async() => {
        const res = await axios.get(URL + '/main/goals', 
            {withCredentials: true} 
        );
        const goals = res.data
        console.log(goals)
        const goals_summary = this.create_roundup_items_summary(goals)

        let generated_goals = goals_summary.map((item) => {
            const amount = item.amount
            const goal_header_text_map = {
                'emergency_fund': `Emergency Fund`,  
                'retirement': `Retirement`, 
                'loans': `Loans`
            }

            const goal_subtext_map = {
                'emergency_fund': `Save $${amount}`,  
                'retirement': `Save $${amount}`, 
                'loans': `Pay off $${amount} (if student debt payments are resumed)`
            }
            const goal_text = goal_header_text_map[item.item_name]
            const subtext = goal_subtext_map[item.item_name]
            return <BubbleCard 
                        size='tall' 
                        text={goal_text} 
                        next_page={item.next_page} 
                        logo={item.logo}
                        subtext={subtext}
                        go_forward='yeah'
                    >
                    </BubbleCard>
        })
        this.setState({
            goals: generated_goals, 
            loading: false
        }, console.log(generated_goals))
    }

    create_roundup_items_summary = (roundup_items) => {
        // const roundup_items = this.state.roundup_items
        const e_fund_part_one = (roundup_items[0] !== undefined) ? roundup_items[0].amount : 0
        const high_interest_debt = (roundup_items[1] !== undefined) ? roundup_items[1].amount : 0
        const moderate_interest_debt = (roundup_items[2] !== undefined) ? roundup_items[2].amount : 0
        const roth = (roundup_items[3] !== undefined) ? roundup_items[3].amount : 0
        const four_oh_one_k = (roundup_items[4] !== undefined) ? roundup_items[4].amount : 0

        const emergency_fund_amount = Math.round((e_fund_part_one) / 100) * 100 // parts 1 and 2
        const debt_amount =  Math.round((high_interest_debt + moderate_interest_debt) / 100) * 100 // high and moderate
        const retirement_amount = Math.round((roth + four_oh_one_k) / 100) * 100

        let roundup_items_summary = [
            {'item_name': 'emergency_fund', 'amount': emergency_fund_amount, 'next_page': '/emergency_fund', 'logo': <BsPiggyBank size={25}/>},
            {'item_name': 'retirement', 'amount': retirement_amount, 'next_page': '/retirement', 'logo': <BsUnlock size={25}/>},
            {'item_name': 'loans', 'amount': debt_amount, 'next_page': '/loans', 'logo': <BsBank size={25}/>}
        ]
        roundup_items_summary = roundup_items_summary.filter(item => item.amount !== 0)
        return roundup_items_summary
    }

    render() {
        return (
            this.state.goals === null?
            <LoadingScreen></LoadingScreen> :
            <div>
                <ColorbarHeader header_text={`${(new Date()).getFullYear()} Goals`}></ColorbarHeader>
                <div> 
                    {this.state.goals}
                </div>
                <Navbar
                    goals_class={this.props.goals_class}
                    checklist_class={this.props.checklist_class}
                    feedback_class={this.props.feedback_class}
                    profile_class={this.props.profile_class}
                    set_active_navbar={this.props.set_active_navbar}
                />
            </div>

        );
    }
}

export default withRouter(Goals)
