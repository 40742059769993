import React, { Component } from 'react';
import "./todo_help.css"
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import { withRouter, Link } from "react-router-dom";
import Button from "../continue_button/continue_button.component";
import Navbar from "../navbar/navbar.component.js"
import { BsArrowRight, BsArrowRightShort} from 'react-icons/bs';


 
class TodoHelp extends Component {
    constructor(props){
        super(props)
        this.state = {
            item: this.props.match.params.item,
            amount: this.props.location.amount, 
            debt_type: this.props.location.debt_type,
            interest_rate: Math.floor(this.props.location.interest_rate)

        }

        this.header_text = {
            'emergency_fund': `Build up an emergency fund`,  // figure out where we need to store the amounts, are they passed in? 
            'roth': `Contribute $${this.state.amount} towards your Roth IRA`, 
            'roth_account': `Open a Roth IRA`, 
            '401k': `Set your 401k contribution to ${this.state.amount}%`, 
            'student': `Pay $${this.state.amount} off your student loans`,
            'credit': `Pay $${this.state.amount} off your credit card debt`
        }

        this.how = {
            'emergency_fund': `Your emergency fund is just your savings account. All you have to do is transfer the amount from your checking to your savings!`,  
            'roth': `You'll need to transfer money from your bank into your Roth IRA account. Don't stop there though! After making the transfer, you need to actually make an investment in the account. We recommend VTSAX or VOO because they are index funds which are much safer than buying stock in individual companies.`, 
            'roth_account': `You can do this through an investment brokerage. We recommend Vanguard because they offer low fees and are relatively easy to use.`, 
            '401k': `Typically, your 401k contributions are handled automatically by your employer. All you have to do is edit your contribution amount or percentage based on our recommendation. This is typically done within the account that your 401k lives in (Fidelity, Vanguard, etc.)`, 
            'student': `You will do this through your student loan provider. You should be able to pay by linking your bank account. ` ,   
            'credit': `You can pay this off through your credit card provider's website or app. You should be able to pay by linking your bank account. `    

        }

        this.why = {
            'emergency_fund': `Life happens. Layoffs, unexpected medical bills, whatever it is, building up an emergency fund can give you peace of mind knowing you have a cushion.`,  // figure out where we need to store the amounts, are they passed in? 
            'retirement': `Save $${this.state.amount} for retirement`, 
            '401k': "A 401k is just an imaginary wrapper around an investment account. A regular investment account like a Robinhood makes you pay taxes twice, once when you get your paycheck and again when you do your tax return. A 401k saves you money on taxes because you're only taxed ONCE (when you withdraw the money in retirement). Contributing to your 401k actually lowers your taxable income come tax season (ex: your salary is $65,000 and you contribute $10,000 to your 401k, so your taxable income is only $55,000)",

            'roth': "Think of a Roth IRA as an imaginary wrapper around an investment account. A regular investment account like a Robinhood makes you pay taxes twice, once when you get your paycheck and again when you do your tax return. A Roth IRA saves you money on taxes because you're only taxed ONCE (when you get paid). As your money grows in this account over time, you can withdraw it tax-free.",
            'roth_account': "Think of a Roth IRA as an imaginary wrapper around an investment account. A regular investment account like a Robinhood makes you pay taxes twice, once when you get your paycheck and again when you do your tax return. A Roth IRA saves you money on taxes because you're only taxed ONCE (when you get paid). As your money grows in this account over time, you can withdraw it tax-free.",
            // TODO loan stuff needs the % rate so we can explain to people. 
            // we can just keep it general , no type involved. but for that we'll need to figure out how to pass it in
            'low_interest_debt': `The interest rate on your loan is only ${this.state.interest_rate}% so you don’t need to worry about paying them off ASAP. You will be better off investing that money in the stock market instead because it returns 7% annually on average. (7% > ${this.state.interest_rate}% so the gains should outweigh the interest)`  ,  
            'moderate_interest_debt': `This is your highest interest rate loan (${this.state.interest_rate}%) and the interest will compound quickly so you should pay it off as fast as you can.`    ,
            'high_interest_debt': `This is your highest interest rate loan (${this.state.interest_rate}%) and the interest will compound quickly so you should pay it off as fast as you can.`    

        }
    }
  
    render() {
        return (
            <div className='help'>
                <ColorbarHeader header_text={this.header_text[this.state.item]}></ColorbarHeader>
                <div className='help-section'>
                    <h1>How do I do that?</h1>
                    <p>{this.how[this.state.item]}</p>
                    {this.state.item === 'roth_account'? 
                    <div className='todo_item'> 
                        <div className='settings-left'>
                            <a href='https://personal1.vanguard.com/ona-open-account/account-selection/overview' target="_blank">Vanguard's website</a> 
                        </div>
                        <div className='settings-right'>
                            <BsArrowRight></BsArrowRight>
                        </div>
                        
                    </div>: 
                    null
                    }
                </div>
                <div className='help-section'>
                    <h1>Why am I doing this?</h1>
                    <p>{this.state.debt_type !== null ? this.why[this.state.debt_type] : this.why[this.state.item]}</p>
                </div>
                <div>
                    <button className="continue signup back"><Link to="/checklist">Back</Link></button> 
                </div>
                    <Navbar
                        goals_class={this.props.goals_class}
                        checklist_class={this.props.checklist_class}
                        feedback_class={this.props.feedback_class}
                        profile_class={this.props.profile_class}
                        set_active_navbar={this.props.set_active_navbar}
                    />
            </div>
        )
    }
}
export default withRouter(TodoHelp)