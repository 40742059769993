import React, { Component } from 'react';
import "./landing_page.css";
import { withRouter, Link} from "react-router-dom";
import Navbar from "../navbar/navbar.component.js"
import LoadingScreen from "../loading_screen/loading_screen.component";
import axios from "axios"
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import history from '../../history.js'
import happy_graphs from '../../assets/happy graphs.png'


let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}

class LandingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount = async() => {

    }


    render() {
        return (
            <div className='full-container blue'>
                <div className='container'>
                    <div className='top-bar'>
                        <div className='company-name'>
                            <p>Envoy Finance</p>
                        </div>
                        <div className='login-signup'>
                            <Link to='/signup'>Sign Up</Link>
                            <Link to='/login'>Login</Link>
                        </div>
                    </div>
                    <div className='full-container main-text'>
                        <p className='header'>Start managing your money - No research required.</p>
                        <p className='normal-text'>In the age of information, why is it still so hard to figure out what to do with your money? With Envoy, just let us know your financial situation and we'll tell you what you need to do.</p>
                    </div>
                    <div className='screenshot'>
                        <img src={happy_graphs} alt='happy graphs' />
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(LandingPage)
