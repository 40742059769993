import React, { Component } from 'react';
import "./checklist.css"
import Button from "../continue_button/continue_button.component";
import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import { withRouter, Link } from "react-router-dom";
import TodoItem from "../todo_item/todo_item.component.js"
import Navbar from "../navbar/navbar.component.js"
import axios from "axios"
import LoadingScreen from '../loading_screen/loading_screen.component';
import CurrentGoalProgress from '../current_goal_progress/current_goal_progress.component';
// import EditableLabel from 'react-inline-editing';
import { BsPencil, BsQuestion, BsQuestionCircle} from 'react-icons/bs';

let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}
 
class Checklist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checklist_items: null,
            loading: true, 
            checklist: this.props.checklist_items,
            first_name: this.props.first_name, 
            type:'roth'
        }
        this.get_user_checklist = this.get_user_checklist.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);

    }


    componentDidMount = async() => {
        const res = await axios.get(URL + '/user', 
            {withCredentials: true}  
        );

        const first_name = res.data.firstName;
        this.props.extend_state({first_name: first_name})
        this.setState({first_name: first_name})


        // get current goal
        const current_goal_return_body = await axios.get(URL + '/main/current_goal', 
            {withCredentials: true}  
        );
        const type = current_goal_return_body.data.type;
        const goal_english_name = current_goal_return_body.data.goal_english_name; 
        const amount = current_goal_return_body.data.amount;
        const most_recent_progress = current_goal_return_body.data.most_recent_progress
        const completion_pct = Math.round((most_recent_progress / amount) * 100 * 10 ) / 10

        this.setState({
            // type: type,
            goal_english_name: goal_english_name, 
            amount: amount, 
            most_recent_progress: most_recent_progress, 
            completion_pct: completion_pct, 
            editing_goal_progress: false
        })

        // figure out where the checklist generation should be called
        if (!this.props.checklist_items) {
            this.get_user_checklist()      
        }
        else {
            this.generate_todos()
        }  
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.checklist_loaded !== prevProps.checklist_loaded) {
            console.log('checklist items received: ', this.props.checklist_items)
            this.setState({checklist: this.props.checklist_items}, () =>
                this.generate_todos()
            )
        }

        if (this.state.most_recent_progress !== prevState.most_recent_progress) {
            const completion_pct = Math.round((this.state.most_recent_progress / this.state.amount) * 100 * 10 ) / 10
            this.setState({
                completion_pct: completion_pct
            })
        }
    }


    get_user_checklist = async() => {
        const res = await axios.get(URL + '/checklist/checklist', 
            {withCredentials: true}  
        );
        const checklist = res.data
        this.setState({ 
            checklist: checklist
        }, () => this.generate_todos())
    }

    generate_todos = () => {
        console.log('generating todos', this.state.checklist)
        let generated_checklist_items = this.state.checklist.map((item) => {
            return <TodoItem item={item} key={item._id}></TodoItem>
        })
        this.setState({
            checklist_items: generated_checklist_items, 
            loading: false
        }, console.log(this.props.checklist_loaded))
    }

    handleClick = () => {
        console.log('clicked edit');
        this.setState((prevState) => ({
            editing_goal_progress: !prevState.editing_goal_progress
        })
        )
    }

    handleSubmit = async(event) => {
        event.preventDefault();
        const progress_update = {
            progress: Number(this.state.latest_contribution) + this.state.most_recent_progress, 
            type: this.state.type
        }
        console.log(progress_update)
        await axios.put(URL + '/main/current_goal_progress', progress_update, {withCredentials: true})
        .catch((error) => {
                alert(error.response.data.error)
        });
        //update the goal progress 
        this.setState({
            editing_goal_progress: false, 
            most_recent_progress: Number(this.state.latest_contribution) + this.state.most_recent_progress
        })

    }

    render() {
        return (
            this.state.checklist === null | this.state.checklist === undefined ? 
            <LoadingScreen></LoadingScreen> : 
            <div> 
                <ColorbarHeader header_text={`Welcome, ${this.state.first_name}!`}></ColorbarHeader>
                {/* <h1>Todos</h1> */}
                <div className='rectangle'>
                    <div className='text-container'>
                        <div style={{'width': '90%'}}>
                            <p style={{'fontSize': '1.6em', 'marginBottom': '0', 'marginTop':'2%'}}>{`${this.state.goal_english_name}`}</p>
                        </div>
                        <div style={{'width': '8%', 'alignItems': 'center', 'display': 'flex'}}>
                            {['roth', '401k'].includes(this.state.type) ? 
                                    <button className='corner-circle' onClick={this.handleClick}>
                                        <BsPencil size={15}></BsPencil>
                                    </button> :
                                <Link to={this.state.type}>
                                    <BsQuestionCircle size={25}></BsQuestionCircle>
                                </Link>
                            }

                        </div>
                    </div>
                    {this.state.editing_goal_progress ?
                    <div className='text-container'>
                        <div className='container'>
                        <div style={{'width': '95%'}}>
                            <p>Current Progress: ${this.state.most_recent_progress}</p>
                        </div>
                            <form onSubmit={this.handleSubmit} style={{'width': '100%'}}>
                                <div className='signup-label'>
                                    <label>Latest Contribution: </label>
                                </div>
                                <div className='input-field'>
                                    <input className='form-control signup-input' required type='number' placeholder='$' value={this.state.latest_contribution} onChange={(event) => this.setState({latest_contribution: event.target.value}, () => console.log(this.state))}></input>
                                </div>
                                <div style={{justifyContent: 'center', display: 'flex'}}>
                                    <button type="submit" className='continue feedback update-goal clickable'>Update progress</button>
                                </div>
                            </form>
                        </div>

                    </div> : 
                    <>
                        <div className='text-container'>
                            <p style={{'fontSize': '1.4em', 'margin': '0'}}>Progress: {`$${this.state.most_recent_progress}/$${this.state.amount}`}
                        </p>
                        </div>
                        <CurrentGoalProgress progress={this.state.completion_pct}/> 
                    </>
                    }
                    
                </div>
                    <div className='checklist'>
                        {this.state.checklist.length > 0 ?
                            <div>
                                <h1>to-dos</h1>
                                this.state.checklist_items 
                            </div>: 
                            <div className='container'>
                                <h1 style={{margin: '0 0 0 0'}}>No tasks for you right now.</h1>
                                <p style={{textAlign: 'center', 'margin': '0'}}>just focus on your main goal! </p>
                            </div>
                        }
                    </div>

                    <Navbar
                        goals_class={this.props.goals_class}
                        checklist_class={this.props.checklist_class}
                        feedback_class={this.props.feedback_class}
                        profile_class={this.props.profile_class}
                        set_active_navbar={this.props.set_active_navbar}
                    />
            </div>
            
        )
    }
}

export default withRouter(Checklist)