import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import Button from "../continue_button/continue_button.component";
import "./colorbar_transition.css"
import Confetti from 'react-confetti'


class ColorbarTransition extends Component {
    constructor(props){
        super(props)
        this.state = {
            next_page: null
        }
    }

    componentDidMount(){
        if (this.props.get_next_page !== undefined) {
            const url = this.props.url;
            const page_collection = this.props.page_collection
            const next_page = this.props.get_next_page(url, page_collection);
            this.setState({next_page: next_page}, () => console.log(next_page))
        }
        else {
            this.setState({next_page: this.props.next_page})
        }
    }  

    componentDidUpdate(prev_state){
        if (this.state.next_page !== prev_state.next_page) {
            if (this.props.get_next_page !== undefined) {
                const url = this.props.url;
                const page_collection = this.props.page_collection
                const next_page = this.props.get_next_page(url, page_collection);
                this.setState({next_page: next_page}, () => {
                    if (this.props.remove_account_from_state !== undefined) {
                        const next_account = this.state.next_page.split('/')[2]
                        this.props.remove_account_from_state('accounts_selected', next_account)
                    }
                })

            }
            else {
                this.setState({next_page: this.props.next_page})
            }
        }
    }  

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="select_accounts">
                {/* {this.props.celebration? 
                    <Confetti
                    width={800}
                    height={1000}
                    numberOfPieces={250}
                    tweenDuration={10000}
                    initialVelocityY={10}
                    // colors={['#6EB275']}
                    // drawShape={ctx => {
                    //     ctx.beginPath()
                    //     for(let i = 0; i < 22; i++) {
                    //     const angle = 0.35 * i
                    //     const x = (0.2 + (1.5 * angle)) * Math.cos(angle)
                    //     const y = (0.2 + (1.5 * angle)) * Math.sin(angle)
                    //     ctx.lineTo(x, y)
                    //     }
                    //     ctx.stroke()
                    //     ctx.closePath()
                    // }}
                    /> : 
                null
                } */}
                <ColorbarHeader header_text={this.props.header_text}/>
                <p className="body_text">{this.props.body_text}</p> 
                {/* body_text style in app.css */}
                
                <Button 
                    next_page={
                        this.props.get_next_page !== undefined ? 
                        this.state.next_page : 
                        this.props.next_page
                    } 
                    continue_action={this.props.continue_action}
                    clickable='clickable'
                />
            </div>
        )
    }
}

export default withRouter(ColorbarTransition)