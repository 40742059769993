import ColorbarHeader from "../colorbar_header/colorbar_header.component";
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import Button from "../continue_button/continue_button.component";
import AccountSelectButton from "../account_select_button/account_select_button.component";
import "./select_accounts.css"


class SelectAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checking: false, 
            savings: false, 
            four_oh_one: false, 
            ira: false, 
            loan: false, 
            clickable: ''
        };
        this.add_selection = this.add_selection.bind(this)
    }
    
    add_selection = (item) => {
        this.setState(prevState => {
            let new_state = Object.assign({}, prevState);
            const prev_val = new_state[item]
            let updated_state = new Array()
            updated_state[item] = !prev_val
            return updated_state;                                
        }   , () => {
            if (this.state.checking === true | 
                this.state.student_loan === true |  
                this.state.credit_card_debt === true) {
                    this.setState({clickable: 'clickable'})
            }
            else {
                this.setState({clickable: ''})
            }
        } 
        )   
    }
 
    render() {
        return (
            <div className="full-container">
                <ColorbarHeader header_text="Please select all of the following things that you have"/>
                <div className="container">
                    <AccountSelectButton item="checking" text="Checking/Savings Account" add_selection={this.add_selection}></AccountSelectButton>
                    {/* <AccountSelectButton item="four_oh_one" text="401k" add_selection={this.add_selection}></AccountSelectButton>
                    <AccountSelectButton item="ira" text="Roth IRA / IRA" add_selection={this.add_selection}></AccountSelectButton> */}
                    <AccountSelectButton item="student_loan" text="Student Loan" add_selection={this.add_selection}></AccountSelectButton>
                    <AccountSelectButton item="credit_card_debt" text="Credit Card Debt" add_selection={this.add_selection}></AccountSelectButton>
                    {/* <AccountSelectButton item="car_loan" text="Car Loan" add_selection={this.add_selection}></AccountSelectButton>                */}
                </div>
                <Button 
                    next_page="/connect_accounts" 
                    continue_action={this.props.set_accounts_selected} 
                    current_selections={Object.assign({}, this.state)}
                    clickable={this.state.clickable}
                />

            </div>
        )
    }
}

export default withRouter(SelectAccounts)