import React, { Component } from 'react';
import "./feedback.css";
import axios from 'axios';
import { withRouter, Link} from "react-router-dom";
import Navbar from "../navbar/navbar.component.js"
import ColorbarHeader from "../colorbar_header/colorbar_header.component";

let URL;
if (process.env.NODE_ENV === "production") {
  URL = "https://api.envoyfinance.app"
} else if (process.env.NODE_ENV === "development") {
  URL = "http://localhost:5000"
}

class Feedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            feedback: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(event)
    {
        event.preventDefault();

        const user_feedback = {
            feedback: this.state.feedback
        }

        // store in database (will prob have to change localhost to something else)
        axios.post(URL + '/main/feedback', user_feedback, {withCredentials: true}).then(response => { 
                alert("Thanks for submitting your feedback!")
                // clear the form value and stuff
             })
          .catch((error) => {
                alert(error.response.data.error)
          });
    }


    render() {
        return (
            <div id="feedback" className = "full-container">
                <ColorbarHeader header_text="Let us know how we can improve!"/>
                <form id="feedback-form" onSubmit={this.handleSubmit}>
                    <div id="feedback-container">
                        <textarea 
                            className="form-control feedback-input" 
                            required type="feedback" 
                            value={this.state.feedback} 
                            onChange={(event) => this.setState({feedback: event.target.value})}
                            rows="9"
                            cols="45"
                        >               
                        </textarea>
                    {/* <p className = "errorMessage">{this.state.errorMessage}</p> */}
                    </div>
                    <div className='centered-button'>
                        <button type="submit" id="feedback-button" className="continue clickable">Submit your feedback!</button>
                    </div>
                </form>
                <Navbar
                    goals_class={this.props.goals_class}
                    checklist_class={this.props.checklist_class}
                    feedback_class={this.props.feedback_class}
                    profile_class={this.props.profile_class}
                    set_active_navbar={this.props.set_active_navbar}
                />
            </div>
                
        );
    }
}

export default withRouter(Feedback)
