import React, { Component } from 'react';
import "./colorbar_header.css"

// todo figure out how to best practice to change the color and words of this header
// i think that we just use props and pass in text and a color maybe, then re-render the bar? 
// each page will have its own colorbar component
export default class ColorbarHeader extends Component {
    render() {
        return (
            <div className="headerbar">
                <div className='header-container'>
                    <h1 className="header_text">{this.props.header_text}</h1>
                    <h2 className='header_text'>{this.props.subheader_text}</h2>
                </div>
            </div>
        )
    }
}